import React from "react";

import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import BanerMDD from "../../components/BanerMDD/BanerMDD";

import locales from "../../constants";

const Condo = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Condo",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #ec2f22, #e22e22, #d72c20, #d92b20, #d02a1f )",
        icons: "#ff3c00",
        navClass: "condo__nav",
        ogImage: require("../../assets/img/portfolio/condo_top_back.jpg"),
      }}
      // langSwitch={{
      //   langKey: "en",
      //   langSlug: "/en/projects/condo/",
      // }}
    >
      <PortfolioHeader name="condo" height="138" />

      <div className="condo">
        <section className="container-fluid condo-section-1" id="info">
          <div className="row">
            <div className="col-12 col-md-6">
              <h1>Condo</h1>
              <p>Portal internetowy</p>
            </div>
            <div className="col-12 col-md-6">
              <p>
                Z produktami inwestycyjnymi pracujemy od 2016 roku. Byliśmy
                jedną z pierwszych agencji w Polsce, która prowadziła kampanie
                online dla condohoteli. Z firmą Oak Property mieliśmy okazję
                współpracować przy inwestycji Hotel Horizon. Tym razem
                powierzono nam zadanie zaprojektowanie na nowo, działającego już
                na rynku, portalu inwestycyjnego{" "}
                <a href="https://condo.pl/" target="_blank" rel="noreferrer">
                  condo.pl .
                </a>
              </p>
            </div>
          </div>
        </section>

        <section className="container-fluid condo-section-2 py-0">
          <div className="row justify-content-between">
            <div className="col-12  col-xl-4">
              <h2>Poprzednia wersja serwisu</h2>
              <p>
                Poprzedni serwis internetowy nie realizował celów biznesowych
                klienta, dlatego po przeprowadzeniu studium wykonalności
                projektu nakreśliliśmy założenia, które miały zmienić ten stan
                rzeczy. Przed nami stanęło kilka wyzwań, m.in:
              </p>
              <ul>
                <li>
                  zaprojektować warstwę UX w taki sposób, aby poprawić konwersję
                  zapytań do biura sprzedaży
                </li>
                <li>utrzymać pozycje w wyszukiwarce Google</li>
                <li>poprawić szybkość działania portalu</li>
                <li>
                  zapewnić stały support oraz gotowość do ciągłego rozwoju
                  portalu
                </li>
              </ul>
            </div>

            <div className="col-12 col-xl-8 col-xxl-6 page-photos">
              <div className="page-photos__prev">
                <p>Poprzednia wersja</p>
                <img
                  className="img-fluid"
                  src={require("../../assets/img/portfolio/condo_prevPage.jpg")}
                  alt="Poprzednia wersja strony Condo"
                />
              </div>
              <div className="page-photos__current">
                <p>Obecna wersja</p>
                <img
                  className="img-fluid"
                  src={require("../../assets/img/portfolio/condo_currentPage.jpg")}
                  alt="Obecna wersja strony Condo"
                />
              </div>
            </div>

            <div className="col-12 col-lg-9 col-xxl-7 application-photos">
              <div className="application-photos__prev">
                <p>Poprzednia wersja</p>
                <img
                  className="img-fluid"
                  src={require("../../assets/img/portfolio/condo_prevApplication.jpg")}
                  alt="Poprzednia wersja aplikacji Condo"
                />
              </div>
              <div className="application-photos__current">
                <p>Obecna wersja</p>
                <img
                  className="img-fluid"
                  src={require("../../assets/img/portfolio/condo_currentApplication.jpg")}
                  alt="Obecna wersja aplikacji Condo"
                />
              </div>
            </div>
          </div>

          <div className="row justify-content-end">
            <div className="col-12  col-xl-6">
              <h2>Warstwa UX</h2>
              <p>
                Pracując nad skuteczną warstwą UX nie odkrywaliśmy niezbadanych
                lądów. Korzystaliśmy z naszego doświadczenia i wiedzy, dzięki
                czemu na bazie zastanej mapy strony stworzyliśmy zupełnie nową
                architekturę informacji oraz architekturę treści.
                Zdiagnozowaliśmy brakujące obszary, elementy i treści, które są
                istotne z perspektywy potrzeb zdefiniowanych wcześniej grup
                docelowych i uzupełniliśmy nowy serwis. Zaprojektowaliśmy
                również szereg całkiem nowych funkcjonalności, m.in; wygodna,
                szczegółowa wyszukiwarka oferty czy też porównywarka inwestycji
                i apartamentów. Wszystko to dla wygody użytkowników oraz
                zwiększenia ilości zapytań do biura sprzedaży.
              </p>
            </div>

            <div className="ux-images ">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/condo_ux-1.jpg")}
                alt="Makieta strony"
              />
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/condo_ux-2.jpg")}
                alt="Makieta strony"
              />
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/condo_ux-3.jpg")}
                alt="Makieta strony"
              />
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/condo_ux-4.jpg")}
                alt="Makieta strony"
              />
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/condo_ux-5.jpg")}
                alt="Makieta strony"
              />
            </div>
          </div>
        </section>

        <section className="condo-section-3">
          <div className="background">
            <h2>Design, czyli nowe szaty króla</h2>
            <p>
              Condo.pl to lider branży. Tym większym problemem poprzedniej
              wersji portalu była jego warstwa wizualna, która kompletnie nie
              oddawała skali projektu. Opracowaliśmy nowy design system, który
              posłużył do zbudowania wszystkich podstron projektu. Całość
              została wdrożona w oparciu o opracowane wcześniej widoki UX.
            </p>
          </div>

          <div className="scroll_wrapper scroll_wrapper--macbookPro">
            <div className="img_wrapper">
              <img
                className="img-fluid"
                draggable="false"
                src={require("../../assets/img/portfolio/condo_entire_page.jpg")}
                alt=""
              />
            </div>
          </div>

          <div className="screens">
            <div className="row">
              <div className="col-12 col-lg-6">
                <img
                  src={require("../../assets/img/portfolio/condo_design-1.jpg")}
                  className="screens-1 img-fluid"
                  alt="Design nowej strony"
                />
              </div>
              <div className="col-12 col-lg-6">
                <img
                  src={require("../../assets/img/portfolio/condo_design-2.jpg")}
                  className="screens-2 img-fluid"
                  alt="Design nowej strony"
                />
                <img
                  src={require("../../assets/img/portfolio/condo_design-3.jpg")}
                  className="screens-3 img-fluid"
                  alt="Design nowej strony"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-6">
                <img
                  src={require("../../assets/img/portfolio/condo_design-mobile.png")}
                  className="screens-4"
                  alt="Design na smartphonie"
                />
              </div>
              <div className="col-12 col-lg-6">
                <img
                  src={require("../../assets/img/portfolio/condo_design-4.jpg")}
                  className="screens-5 img-fluid"
                  alt="Design nowej strony"
                />
                <img
                  src={require("../../assets/img/portfolio/condo_design-5.jpg")}
                  className="screens-6 img-fluid"
                  alt="Design nowej strony"
                />
                <img
                  src={require("../../assets/img/portfolio/condo_design-6.jpg")}
                  className="screens-7 img-fluid"
                  alt="Design nowej strony"
                />
              </div>
            </div>
          </div>

          <img
            src={require("../../assets/img/portfolio/condo_design-banner.jpg")}
            className="banner"
            alt="Mobilne widoki ułożone obok siebie"
          />
        </section>

        <section className="condo-section-4 pb-0">
          <div className="row">
            <div className="col-12 col-md-6 order-1">
              <h2>Utrzymanie pozycji</h2>
              <p>
                Absolutnie kluczowym obszarem projektu było to, aby pomimo nowej
                struktury, zmiany serwera i warstwy wizualnej nie nastąpiła
                utrata wypracowanych przez lata pozycji w wyszukiwarce Google.
                Już na starcie projektu na bazie naszego know how
                przedstawiliśmy naszemu klientowi oraz agencji SEO procedurę w
                jaki sposób planujemy podejść do sprawy, aby zminimalizować
                ryzyko utraty pozycji. Opracowaliśmy dokument audytujący
                dotychczasowy serwis i ustaliliśmy wszystkie kluczowe obszary,
                które muszą zostać wdrożone na nowym portalu.
              </p>
              <br />
              <p>
                W trakcie prac agencja SEO mogła wprowadzić dodatkowe
                usprawnienia, które nie były możliwe w poprzedniej wersji
                projektu z uwagi na ograniczenia technologiczne. Po oddaniu
                projektu otrzymaliśmy raport, który wykazał, że nie tylko
                wszystkie frazy zostały utrzymane, ale został odnotowany łączny
                wzrost o 25 pozycji na istotne dla klienta słowa i frazy
                kluczowe.
              </p>
            </div>
            <div className="col-12 col-md-6 d-flex align-items-center justify-content-end order-2">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/condo_screen-google.jpg")}
                alt="Fraza wpisana w google"
              />{" "}
            </div>
            <div className="d-flex align-items-center col-12 col-md-6 order-4 order-md-3">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/condo_screen-webpagetest.jpg")}
                alt="Wynik testu wydajności strony"
              />
            </div>
            <div className="col-12 col-md-6 order-3 order-md-4 ">
              <h2>Poprawa szybkości działania portalu</h2>
              <p>
                Aby zadbać o błyskawiczną szybkość działania (aktualny czas
                ładowania wynosi 1,4 sekundy), poprawiając jednocześnie
                bezpieczeństwo całej infrastruktury IT Klienta, zmieniliśmy
                technologię wdrożenia portalu. Tak jak Facebook czy Instagram
                postawiliśmy na React.js (wspierany przez Gatsby.js). Dzięki tej
                technologii szybkość działania portalu jest bezkonkurencyjna i
                zostawia daleko w tyle standardowe technologie jak html, css i
                java script.
              </p>
            </div>
            <div className="col-12 col-md-6 order-5">
              <h2>Zabezpieczenie rozwoju</h2>
              <p>
                Oddanie nawet najbardziej dopracowanego projektu to dopiero
                początek współpracy. Wraz z naszym klientem ustaliliśmy warunki
                SLA, które pozwoliły nam wspólnie rozwijać projekt w taki
                sposób, aby wdrażanie nowych pomysłów spotykało się z
                praktycznie natychmiastowym podjęciem działania po stronie
                zespołu.
              </p>
              <p>
                Projekt jest prowadzony w systemie do którego dostęp ma również
                Klient, który za jego pośrednictwem zleca zadania. Każdemu z
                nich nadawane są odpowiednie dla postępu prac statusy, dzięki
                czemu Klient może w czasie rzeczywistym obserwować, komentować
                oraz nadawać priorytet prowadzonym działaniom.
              </p>
            </div>
            <div className="col-12 col-md-6 d-flex align-items-center justify-content-end order-6">
              <img
                className="img-fluid "
                src={require("../../assets/img/portfolio/condo_screen-clickup.jpg")}
                alt="Lista zadań ClickUp"
              />
            </div>
          </div>
        </section>
        
        <BanerMDD />

        <PortfolioNav data={nav} locales={locales[lang]} />
      </div>
    </Layout>
  );
};

export default Condo;
